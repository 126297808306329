<template>
  <span/>
  <div style="width:100%; display:block;">
    <table v-if="type === 'list2'">
      <div style="width:100%; display:block;text-align:center;">
        <table class="trade-info" v-if="question.TRADE === undefined">
          <tr>
            <td class="no-img">IDX</td>
            <td class="no-img"> {{ question.TRADE_IDX }} </td>
            <td class="no-img">상품링크</td>
            <td class="no-img"><a :href="'https://geek9.kr/market?idx=' + question.TRADE_IDX" target="_blank">보기</a></td>
          </tr>
          <tr>
            <td class="no-img">문의일</td>
            <td class="no-img"> {{ this.api.getDateByFormat(question.REG_DATE, 'YYYY.MM.DD') }} </td>
            <td class="no-img">답변일</td>
            <td class="no-img">{{ question.MOD_DATE===null ? '답변전' : this.api.getDateByFormat(question.MOD_DATE, 'YYYY.MM.DD') }}</td>
          </tr>
          <tr>
            <td class="no-img">문의내용</td>
            <td class="no-img" colspan="3" v-html="question.DESC.replace(/\n/g, '<br>')"/>
          </tr>
        </table>
        <table class="trade-info" v-else>
          <tr >
            <td class="img link" v-if="question.TRADE.IMG_LIST !== undefined" rowspan="3"><img style="max-height:70px;" @click="click({type:'showImg', url:this.api.WSRV_POCKETBASE_URL + question.TRADE.IMG_LIST.split(',').replace(/ /g, '')})" :src='this.api.WSRV_POCKETBASE_URL + question.TRADE.IMG_LIST.split(",")[0].replace(/ /g, "")'></td>
            <td class="no-img" style="font-weight: bold;width:15%;">IDX</td>
            <td class="no-img" style="font-weight: normal;width:25%;"> {{ question.TRADE_IDX }} </td>
            <td class="no-img" style="font-weight: bold;width:15%;">상품링크</td>
            <td class="no-img" style="font-weight: normal;width:25%;"><a :href="'https://geek9.kr/market?idx=' + question.TRADE_IDX" target="_blank">보기</a></td>
          </tr>
          <tr>
            <td class="no-img">문의일</td>
            <td class="no-img"> {{ this.api.getDateByFormat(question.REG_DATE, 'YYYY.MM.DD') }} </td>
            <td class="no-img">답변일</td>
            <td class="no-img">{{ this.api.getDateByFormat(question.MOD_DATE, 'YYYY.MM.DD') }}</td>
          </tr>
          <tr>
            <td class="no-img">문의내용</td>
            <td class="no-img" colspan="3" v-html="question.DESC.replace(/\n/g, '<br>')"/>
          </tr>
        </table>
        <br>
        <table>
          <tr class="trade-reply" v-if="!(question.REPLY === null || question.REPLY === undefined)">
            <td style="font-weight: bold;width:15%;">판매자답변<br><span style="font-weight: normal;font-style: italic;" v-if="question.TRADE !== undefined">({{ this.api.textCut(question.TRADE.ID, 7).replace(/\./g, '*') }})</span></td>
            <td colspan="4" v-html="question.REPLY.replace(/\n/g, '<br>')"/>
          </tr>
        </table>
      </div>
    </table>
    <table v-else-if="type === 'list'">
      <div style="width:100%; display:block;">
        <table>
          <tr>
            <td class="img link" v-if="!(imgUrl === undefined || imgUrl === '')" rowspan="3"><img style="max-height:70px;" @click="click({type:'showImg', url:imgUrl})" :src='this.api.WSRV_POCKETBASE_URL + imgUrl'></td>
            <td class="no-img" style="font-weight: bold;width:15%;">주문일</td>
            <td class="no-img" style="font-weight: normal;width:25%;"> {{ this.api.getDateByFormat(regDate.substring(0,8), 'YYYY.MM.DD') }} </td>
            <td class="no-img" style="font-weight: bold;width:15%;">상품링크</td>
            <td class="no-img" style="font-weight: normal;width:25%;"><a :href="url" target="_blank">보기</a></td>
          </tr>
          <tr>
            <td class="no-img">대행국가</td>
            <td class="no-img"> {{ country }} </td>
            <td class="no-img">진행상태</td>
            <td class="no-img">{{ this.api.getOrderStatus(status) }}</td>
          </tr>
          <tr>
            <td class="no-img">메시지</td>
            <td class="no-img" colspan="3" v-html="content.replace(/\n/g, '<br>')"/>
          </tr>
        </table>
      </div>
    </table>
    <table v-else-if="title === undefined && content !== undefined">
      <br>
      <div v-if="content === null || content === undefined || content === ''" ref="content" type="text" ></div>
      <div v-else ref="content" type="text" v-html="content.replace(/\n/g, '<br>')"></div>
      <br>
    </table>
    <table v-else>
      <tbody>
        <tr style="height: 30px;">
          <td v-if="this.seq === undefined" class="text-left">공지
            <input ref="noticeYn" type="checkbox" :checked=noticeYn style="width:fit-content;margin-left: 10px;"/>
          </td>
          <td v-else class="text-left">SEQ 
            <span ref="seq" style="font-weight: normal;margin-left: 5px;">{{this.seq}}</span>
          </td> 
          <td class="text-left" style="font-weight: bold;border-right: 1px dotted gray;">사용 
            <input ref="useYn" type="checkbox" :checked=useYn style="width:fit-content;margin-left: 10px;"/>
          </td> 
          <td class="text-left">우선순위</td> 
          <td class="qna_sub_style" >
            <input ref='priority' step="1" type="number" :value="priority" style="width:80%;text-align: center;"/>
          </td>
        </tr>
        <tr style="height: 30px;">
          <td class="text-left">제목</td> 
          <td class="qna_sub_style">
            <input ref='title' type="text" :value="title" style="width:80%;"/>
          </td>
          <td class="text-left">유형</td> 
          <td class="qna_sub_style" >
            <select ref="type" :value="type" style="width:82%;text-align: center;" @change="change()">
              <option value="">선택</option>
              <option value="popup">popup</option>
              <option value="youtube">youtube</option>
              <option value="mypage">mypage</option>
              <option value="lottoNum">lottoNum</option>
              <option value="innomax">innomax</option>
              <option value="policy">policy</option>
              <option value="hscode">hscode</option>
              <option value="chatbot-qa">chatbot-qa</option>
              <option value="chatbot-recm">chatbot-recm</option>
              <option value="articles">articles</option>
            </select>
          </td>
        </tr>
        <tr v-if="this.seq !== undefined" style="height: 30px;">
          <td style="font-weight: bold;" class="text-left">공지
            <input ref="noticeYn" type="checkbox" :checked=noticeYn style="width:fit-content;margin-left: 10px;"/>
          </td> 
        </tr>          
        <tr>
          <td colspan="4" style="width: 100%;">
            <textarea ref="content" type="text" :value="content" />
          </td>
        </tr>
      </tbody>
    </table>
    <table v-if="this.seq !== undefined">
      <tr>
        <td style="width:50%;">
          <input @click="click({type:'삭제'})" type="button" value="삭제">
        </td>
        <td style="width:50%;">
          <input @click="click({type:'수정'})" type="button" value="수정">
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    idx: Number,
    seq: Number,
    regDate: String,
    url: String,
    imgUrl: String,
    status: Number,
    country: String,
    question: Object,
    priority: Number,
    title: String,
    type: String,
    orderType: String,
    content: String,
    useYn: Boolean,
    noticeYn: Boolean
  },
  data() { 
    return { 
      policyGrp:[]
    } 
  },
  methods: {
    async change() {
      if(this.$refs.type.value === 'policy') {
        let grp = ''
        if(this.policyGrp.length === 0) {
          await this.api.axiosGql( "SELECT_POLICY_GRP", {type:"SELECT_POLICY_GRP"} )
          .then( async (result) => {
            const json = result.data
            for(let i=0; i<json.length; i++) {
              if(grp === '') grp = json[i].TITLE
              else grp +=',' + json[i].TITLE
            }
          })
          .catch( (err) => {
            console.log(err)
          }) 
        }
        if(grp === '') this.$refs.title.value = '배송/주문/분쟁/'
        else this.$refs.title.value = grp
      } else this.$refs.title.value = ''
    },
    async click(param) {
      const type = param.type
      if(type === '삭제') {
        if(confirm(type + "할까요?")) {
          await this.api.axiosGql( "DELETE_NOTICE", {type:"DELETE_NOTICE", seq:this.seq} )
          .then( async (result) => {
            if( !(result === null || result === undefined) ) {
              if(Number(result.data) === this.seq) {
                this.$notify('삭제되었습니다.')
                this.$emit('noticeClick', 'SELECT_NOTICE')
              }
            }
          })
          .catch( (err) => {
            console.log(err)
          })
        }
      } else if(type === '수정') {
        if(confirm(type + "할까요?")) {
          await this.api.axiosGql("UPDATE_NOTICE", {type:"UPDATE_NOTICE", seq:this.seq, noticeType:this.$refs.type.value, useYn:Boolean(this.$refs.useYn.checked), noticeYn:Boolean(this.$refs.noticeYn.checked), priority:Number(this.$refs.priority.value), title:this.$refs.title.value, content:this.$refs.content.value})
          .then( async (result) => {
            if(result !== null && result !== undefined) {
              if(Number(result.data.SEQ) === this.seq) {
                this.$notify('수정되었습니다.')
                this.$emit('noticeClick', 'SELECT_NOTICE')
              }
            }
          })
          .catch( (err) => {
            console.log(err)
          })
        }
      } else if(type === 'showImg') {
        const imgUrl = param.url
        const img = new Image()
        window.open(imgUrl, '_blank', `location=no;width:${img.width}px;height:${img.height}px;`)
      }
    }
  }
}
</script>
<style scoped>
  .text-left {
    width: 70px;
    text-align: center;
    font-weight: bold;
  }
  .qna_sub_style {
    width: 25%;
    border-right: 1px dotted gray;
  }
  textarea {
    height: 100px;
    overflow:visible;
    resize: vertical;
    margin-top: 10px;
    width:98%;
  }
  table  {
    width:100%
  }
  #table_detail tr td, #table_possess_list tr td {
    height:40px; 
  }
  table.trade-info > tr > td.img {
    width:10%;
    border-right: 1px dotted #ffa07a; 
  }
  table.trade-info > tr > td.no-img:nth-child(even){
    width:25%;
    border: 1px dotted #ffa07a; 
  }
  table.trade-info > tr > td.no-img:nth-child(odd){
    text-align: center;
    font-weight: bold;
    width:15%;
    border: 1px dotted #ffa07a; 
  }
  table.trade-info > tr {
    height:30px;
  }
  table.trade-info {
    border-collapse: collapse;
  }
  table.trade-info > tr:first-child td.no-img {
    border-top: 0;
  }
  table.trade-info > tr td.no-img:first-child {
    border-left: 0;
  }
  table.trade-info > tr:last-child td.no-img {
    border-bottom: 0;
  }
  table.trade-info > tr td.no-img:last-child {
    border-right: 0;
  }
  .trade-reply {
    background: aliceblue;
  }
</style>